/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import Pagination from "../../components/pagination/Pagination";
import Spinner from "../../components/spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { MAGIC_NUMBERS } from "../../constant";
import List from "../../components/list/List";
import useUserContext from "../../hooks/useUserContext";
import notify from "../../services/toast";
import StoryCard from "./StoryCard";
import getImpactStories from "./getImpactStories.js";
import GroupRejectModal from "../../components/group_reject_modal/GroupRejectModal";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import ButtonDanger from "../../components/button/ButtonDanger";
import StoryPage from "./StoryPage";
import updateStory from "./updateStory"

function ImpactStoryTimeline() {
  const [stories, setStories] = useState([]);
  const { userState } = useUserContext();
  const navigate = useNavigate();
  const [selectedStories, setSelectedStories] = useState([]);
  const [selectedStory, setSelectedStory] = useState(null);
  const [isUpdatingStory, setIsUpdatingStory] = useState(false);
  const [isStoriesLoading, setIsStoriesLoading] = useState(true);
  const [showRejectModal, setShowRejectModal] = useState(false);

  function renderStories(story, index) {
    return <StoryCard key={index} story={story} />;
  }

  function onClick() {
    navigate("add");
  }

  const [pagination, setPagination] = React.useState({
    total: 0,
    page: 0,
    remaining: 0,
    limit: 11,
  });

  const handleUpdateStatus = async (newStatus) => {
    console.log("in fetch", selectedStory.userId);
    if (selectedStory.approvalStatus !== newStatus) {
      setIsUpdatingStory(true);
      try {
        const body = { approvalStatus: newStatus };
        
        const response = await updateStory(selectedStory._id, body, userState.accessToken);
        console.log("Response from updateStory: ", response); // Add this to see the response
  
        if (response.status === "success") {
          fetchImpactStories(userState.accessToken, 0, pagination.limit); // Fetch updated stories
          notify("Story successfully updated", "info");
          setSelectedStory({ ...selectedStory, approvalStatus: newStatus });
        } else {
          let displayMessage = "";
          if (Array.isArray(response.message)) {
            displayMessage = response.message[0].message;
          } else {
            displayMessage = response.message;
            if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
              displayMessage = "An error has occurred. Please try again later. If this error persists, please contact support.";
            }
          }
          notify(displayMessage, "error");
        }
      } catch (error) {
        console.error("Error updating story: ", error); // Log the error
        notify("Something went wrong. Please try again later", "error");
      } finally {
        setIsUpdatingStory(false);
        if (showRejectModal) {
          setShowRejectModal(false);
        }
      }
    }
  };
  
  const fetchImpactStories = async (accessToken, offset, limit) => {
    setIsStoriesLoading(true);
    try {
      const response = await getImpactStories(
        {
          offset,
          limit,
        },
        accessToken
      );

      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination({ limit, page: page, remaining, total });
        setStories(results);
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage =
              "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("something went wrong");
    } finally {
      console.log("error");
    }
    setIsStoriesLoading(false);
  };

  const handlePrevFetch = async () => {
    await fetchApplications(
      userState.accessToken,
      pagination.page - 1,
      keyword
    );
  };

  const handleNextFetch = async () => {
    await fetchApplications(
      userState.accessToken,
      pagination.page + 1,
      keyword
    );
  };

  const handleRowClick = (item) => {
    setSelectedStory(item);
    setSelectedStories([]);
  };

  useEffect(() => {
    fetchImpactStories(userState.accessToken, 0, pagination.limit);
  }, []);

  console.log(selectedStory);
  return (
    <>
      {!selectedStory && (
        <>

          <List
            isLoading={isStoriesLoading}
            hideCheckbox={true}
            columns={[
              {
                name: "creator",
                width: "w-60",
                value: (story) => (
                  <div className="py-2 capitalize">
                    {story.firstName ? story.firstName : "NA"} {story.lastName ? story.lastName : "NA"}
                  </div>
                ),
                type: "render",
              },
              // {
              //   name: "type",
              //   width: "w-40",
              //   value: (story) => <div className="w-40 bg-success-800 text-white text-center rounded-md p-2">
              //     {story.type ? WORKSHOP_CATEGORY.find(item => item.value === event?.eventCategory).label : "GIVE"}
              //     </div>,
              //   type: "render",
              // },
              {
                name: "title",
                value: (story) => <div className="p-2 uppercase">
                  {story.firstName}
                </div>,
                type: "render",
              },
              {
                name: "Date",
                width: "w-20",
                value: (story) => <div className="py-2">
                  {format(new Date(story.createdAt), "MMM dd")}
                </div>,
                type: "render",
              },
              {
                name: "status",
                width: "w-6",
                value:
                  (story) => (
                    <div className="py-3">
                      <div className={`w-3 h-3 rounded-md ${story.approvalStatus === "approved" ? "bg-primary-400" : story.approvalStatus === "rejected" ? "bg-red-600" : "bg-yellow-400"}`}></div>

                    </div>),

                type: "render",
              },
            ]}
            data={stories}
            selectedItems={selectedStories}
            setSelectedItems={setSelectedStories}
            onRowClick={handleRowClick}
            keyItem="_id"
          />
          <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
        </>
      )}
      {selectedStory && (
        //Navigation
        <div className="mt-8">
          <div className="flex justify-between bg-white mb-3 py-2 items-center shadow ring-1 ring-black ring-opacity-5">

            <div className="flex items-center" onClick={() => setSelectedStory(null)}>
              <div className="flex cursor-pointer px-3">
                <span className="material-icons-outlined text-md">chevron_left</span>
              </div>
              <div>
                <div className="text-success-800 text-sm capitalize">

                  {`Current Status: ${selectedStory.approvalStatus}`}

                </div>
                <div className="text-gray-400 text-sm">
                  {/* {selectedEvent.creator?.emailAddress} */}

                </div>
              </div>
            </div>

            <div className="flex px-3">
              {selectedStory.approvalStatus !== "approved" && (
                <div className="text-right w-28 mr-3">
                  <ButtonPrimary text="Approve" loading={isUpdatingStory} action={() => handleUpdateStatus("approved")} />
                </div>
              )}
              {selectedStory.approvalStatus !== "rejected" && <ButtonDanger text="Reject" loading={isUpdatingStory} action={() => handleUpdateStatus("rejected")} />}
            </div>
          </div>


          <StoryPage story={selectedStory} />


        </div>


      )}
      {/* {showRejectModal && (
        <GroupRejectModal
          open={true}
          onClose={() => {
            setShowRejectModal(false);
          }}
          onSave={(reason) => handleUpdateStatus("rejected")}
        />
      )} */}
    </>
  );
}

export default ImpactStoryTimeline;
