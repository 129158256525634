/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Pagination from "../../components/pagination/Pagination";
import { MAGIC_NUMBERS, APP_LOCATIONS } from "../../constant";
import useUserContext from "../../hooks/useUserContext";
import Select from "react-select";
import notify from "../../services/toast";
import ApplicantCard from "./ApplicantCard";
import { debounce } from "../../services/util";
import { useParams } from "react-router-dom";
import getImpactMarketCityData from "../../pages/impact_dashboard/location_dashboard/getImpactMarketCityData";
import getImpactMarketLocationData from "./requests/getImpactMarketLocationData";
import getImpactStats from "../impact_dashboard/dashboard/getImpactStats";
import getImpactStatsByMarket from "./requests/getImpactStatsByMarket";
import getApplicants from "./requests/getApplicantsSearch";
import { PieChart } from "recharts";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../TestAdmin/Themes";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { capitalizeFirstLetter } from "../../services/util";
import { format } from "date-fns";
import deleteApplication from "./requests/deleteApplication";
import Prompt from "../../components/prompt/Prompt";
import { showPrompt } from "../../services/util";
import { MdLocationPin } from "react-icons/md";
import SULogo from "../../assets/StartUp.png";
import Logo from "../../components/logo/Logo";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

function renderApps(app, index) {
	return <ApplicantCard key={index} application={app} />;
}

function ScholarshipApplications() {
	const params = useParams();
	const { userState } = useUserContext();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const city = params.city;
	const [marketData, setMarketData] = useState([]);
	const [isMarketDataLoading, setIsMarketDataLoading] = useState(true);
	const [pagination, setPagination] = React.useState({
		total: 0,
		page: 0,
		remaining: 0,
		limit: 1000,
	});
	const [keyword, setKeyword] = useState("");
	const [location, setLocation] = useState("");
	const [isApplicantsLoading, setIsApplicantsLoading] = useState("");
	const [isStatsLoading, setIsStatsLoading] = useState(false);
	const [dashStats, setStats] = useState({});
	const award = dashStats.annualAward;

	function handleClick(id) {
		window.open(
			`/dashboard/profile/${id}`,
			"_blank",
			"rel=noopener noreferrer"
		);
	}
	function getRowId(row) {
		return row._id;
	}

	const handleDeletePrompt = (orgName, id) => {
		showPrompt(
			"Delete Application",
			`Are you sure you want to delete "${orgName}'s" application? This action cannot be undone.`,
			deletePromptRef,
			"open",
			setDisplayPromptMessage
		);
		setCurrentId(id);
	};

	const handleDelete = async () => {
		const response = await deleteApplication(currentId, userState.accessToken);
		if (response.status === "success") {
			notify("Application successfully deleted", "info");
			deletePromptRef.current.closePrompt();
			window.location.reload(true);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
	};
	const [displayPromptMessage, setDisplayPromptMessage] = useState({
		heading: "",
		body: "",
	});
	const [currentId, setCurrentId] = useState();
	const deletePromptRef = useRef(null);

	const fetchStats = async (city) => {
		const response = await getImpactStats(city, userState.accessToken);
		if (response.status === "success") {
			setStats(response.data, "stats");
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
		setIsStatsLoading(false);
	};

	const fetchMarketStats = async (location) => {
		const response = await getImpactStatsByMarket(
			location,
			userState.accessToken
		);
		if (response.status === "success") {
			setStats(response.data, "stats");
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
		setIsStatsLoading(false);
	};

	const fetchLocationData = async (accessToken, page, limit, location) => {
		const response = await getApplicants(
			{
				page,
				keyword: keyword,
				limit: limit,
				location: location,
				city: city,
			},
			accessToken
		);
		if (response.status === "success") {
			const { limit, page, remaining, total, results } = response.data;
			setPagination({ limit, page: page, remaining, total });
			setMarketData(results);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
		setIsMarketDataLoading(false);
		//setIsListingCategoryLoading(false);
	};

	const generateCohatchLocation = () => {
		if (city) {
			const cohatchLocation = [];
			cohatchLocation.push(APP_LOCATIONS[city]["locations"]);
			return cohatchLocation.flat();
		} else {
			return [];
		}
	};

	const handleLocationsChange = (event) => {
		const location = event.label;
		fetchLocationData(userState.accessToken, 0, pagination.limit, location);
		setLocation(location);
		fetchMarketStats(location);
	};

	//Search info
	const fetchApplicants = async (accessToken, page, _keyword) => {
		//setIsApplicantsLoading(true);
		const response = await getApplicants(
			{
				page,
				keyword: _keyword,
				limit: pagination.limit,
				city: city,
				location: location,
			},
			accessToken
		);
		if (response.status === "success") {
			const { limit, page, remaining, total, results } = response.data;
			// console.log(results)
			setPagination({ limit, page: page, remaining, total });
			setMarketData(results);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
		setIsApplicantsLoading(false);
	};

	const columns = [
		{
			field: "orgName",
			headerName: "Organization",
			flex: 1,
			renderCell: ({
				row: { type, logoImage, orgName, firstName, lastName, _id },
			}) => {
				return (
					<a href={`/dashboard/profile/${_id}`} target="_blank" rel="noopener noreferrer">
						<div className="float-left">
							<div className="text-center ">
								{logoImage?.small?.url ? (
									<img
										className="h-12 w-12 rounded-full ring-4 ring-white"
										src={logoImage.small.url}
									/>
								) : logoImage?.logoImage?.url ? (
									<img
										className="h-12 w-12 rounded-full ring-4 ring-white"
										src={logoImage.logoImage.url}
									/>
								) : (
									<img
										className="h-12 w-12 rounded-full ring-4 ring-white"
										src={type === "give" ? Logo : SULogo}
									/>
								)}
							</div>
						</div>
						<div className="float-left ml-3 font-bold">
							<h1>{orgName}</h1>
							<p className="text-gray-500">
								{firstName} {lastName}
							</p>
						</div>
					</a>
				);
			},
		},
		{
			field: "type",
			headerName: "Scholarship Type",
			flex: 1,
			renderCell: ({ row: { type, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className="whitespace-nowrap py-4 pl-4 pr-3  text-gray-900 sm:pl-6 capitalize font-bold"
					>
						{type}
					</a>
				);
			},
		},
		{
			field: "appStatus",
			headerName: "App Status",
			flex: 1,
			renderCell: ({ row: { appStatus, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className={`capitalize font-bold ${appStatus === "approved"
							? "text-primary-500"
							: appStatus === "rejected"
								? "text-red-700"
								: "text-gray-500"
							}`}
					>
						{appStatus}
					</a>
				);
			},
		},
		{
			field: "cmStatus",
			headerName: "CM Status",
			flex: 1,
			renderCell: ({ row: { cmStatus, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className={`capitalize font-semibold ${cmStatus === "approved"
							? "text-primary-500"
							: cmStatus === "rejected"
								? "text-red-700"
								: "text-gray-500"
							}`}
					>
						{cmStatus}
					</a>
				);
			},
		},
		{
			field: "coLocation",
			headerName: "Location",
			flex: 1,
			renderCell: ({ row: { coLocation, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
					>
						<MdLocationPin className="float-left text-2xl " />
						{coLocation}
					</a>
				);
			},
		},
		{
			field: "createdAt",
			headerName: "Submission Date",
			flex: 1,
			valueGetter: ({ value }) => value && format(new Date(value), "MM/dd/yyy"),
		},
		{
			field: "marketData",
			headerName: "",
			flex: 1,
			renderCell: ({ row: { orgName, _id } }) => {
				return (
					<div
						onClick={() => handleDeletePrompt(orgName, _id)}
						className="cursor-pointer material-icons-outlined text-red-700"
					>
						delete
					</div>
				);
			},
		},
	];

	const pieChartData = {
		labels: ['Approved Scholars', 'Pending Scholars', 'Denied Scholars', 'Boost Scholars', 'Give Scholars'],
		datasets: [
			{
				data: [
					dashStats.totalApprovedApplications,
					dashStats.totalPendingApplications,
					dashStats.totalDeniedApplications,
					dashStats.totalBoostApplications,
					dashStats.totalGiveApplications,
				],
				backgroundColor: ['#56c271', '#003057', '#D32F2F', '#b2c7c9', '#f66a47'],
				borderWidth: 1,
			},
		],
	};

	// Pie chart options
	const pieChartOptions = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top',
			},
			tooltip: {
				callbacks: {
					label: function (tooltipItem) {
						return `${tooltipItem.label}: ${tooltipItem.raw}`;
					},
				},
			},
		},
	};


	useEffect(() => {
		fetchApplicants(userState.accessToken, 0, "");
		fetchStats(city);
	}, []);

	return (
		<>
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<div className=" mt-3 mx-auto bg-white rounded-md shadow-md p-4 flex flex-col">
					<div className="mt-5">
						<h1>
							<span className="text-2xl font-bold text-gray-900">
								{city.toUpperCase()} Impact Scholarships
							</span>
						</h1>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
						{/* Stats Grid - 2 Columns, 3 Rows */}
						<div className="col-span-1 md:col-span-2 lg:col-span-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">

							{/* First Row */}
							<div className="widget bg-white rounded-lg shadow-md p-6 hover:shadow-xl transition-all">
								<div className="flex flex-col items-center">
									<span className="text-4xl font-bold mb-2">
										${award?.[0]?.annualAward ? award[0].annualAward.toLocaleString() : 0}
									</span>
									<span className="text-gray-500 text-lg">Scholarship Dollars Awarded</span>
								</div>
							</div>

							<div className="widget bg-white rounded-lg shadow-md p-6 hover:shadow-xl transition-all">
								<div className="flex flex-col items-center">
									<span className="text-4xl font-bold mb-2">{dashStats.totalApprovedApplications}</span>
									<span className="text-gray-500 text-lg">Approved Scholars</span>
								</div>
							</div>

							{/* Second Row */}
							<div className="widget bg-white rounded-lg shadow-md p-6 hover:shadow-xl transition-all">
								<div className="flex flex-col items-center">
									<span className="text-4xl font-bold mb-2">{dashStats.totalPendingApplications}</span>
									<span className="text-gray-500 text-lg">Pending Scholars</span>
								</div>
							</div>

							<div className="widget bg-white rounded-lg shadow-md p-6 hover:shadow-xl transition-all">
								<div className="flex flex-col items-center">
									<span className="text-4xl font-bold mb-2">{dashStats.totalDeniedApplications}</span>
									<span className="text-gray-500 text-lg">Denied Scholars</span>
								</div>
							</div>

							{/* Third Row */}
							<div className="widget bg-white rounded-lg shadow-md p-6 hover:shadow-xl transition-all">
								<div className="flex flex-col items-center">
									<span className="text-4xl font-bold mb-2">{dashStats.totalBoostApplications}</span>
									<span className="text-gray-500 text-lg">Boost Scholars</span>
								</div>
							</div>

							<div className="widget bg-white rounded-lg shadow-md p-6 hover:shadow-xl transition-all">
								<div className="flex flex-col items-center">
									<span className="text-4xl font-bold mb-2">{dashStats.totalGiveApplications}</span>
									<span className="text-gray-500 text-lg">Give Scholars</span>
								</div>
							</div>
						</div>

						{/* Pie Chart in 3rd Column */}
						<div className="col-span-1 lg:col-span-1 mt-8 lg:mt-0">
							<div className="widget bg-white rounded-lg shadow-md p-6 hover:shadow-xl transition-all">
								<h3 className="text-2xl font-semibold mb-6">Scholar Application Breakdown</h3>
								<div className="flex justify-center">
									<Pie data={pieChartData} options={pieChartOptions} />
								</div>
							</div>
						</div>
					</div>


					{/*Scholarship Table */}
					<div className="w-full mt-12 flex flex-col sm:flex-row justify-between">
						<Select
							className="border-0 mt-2 w-1/2 sm:mt-0"
							placeholder="Select a COhatch Location"
							name="filter"
							options={generateCohatchLocation()}
							onChange={handleLocationsChange}
							theme={(theme) => ({
								...theme,
								colors: {
									...theme.colors,
									primary25: "#ecf8ef",
									primary: "#56c271",
								},
							})}
						/>
					</div>

					<Box m="20px">
						<Box
							m="40px 0 0 0"
							height="75vh"
							sx={{
								boxShadow: 1,
								// borderColor: colors.grey[400],
								backgroundColor: "#FFFFFF",
								"& .MuiDataGrid-columnHeaders": {
									fontWeight: "bold",
								},
							}}
						>
							{marketData && (
								<DataGrid
									rows={marketData}
									columns={columns}
									getRowId={(row) => row._id}
								/>
							)}
						</Box>
					</Box>
					<Prompt
						header={displayPromptMessage.heading}
						body={displayPromptMessage.body}
						ref={deletePromptRef}
						action={handleDelete}
					/>
				</div>
			</div>
		</>
	);
}

export default ScholarshipApplications;
