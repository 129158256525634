/* eslint-disable */
import React, { useState } from "react";
import useUserContext from "../../hooks/useUserContext";
import submitCommunityPartner from "./submitCommunityPartner";
import notify from "../../services/toast";
import SelectInput from "../../components/select_input/SelectInput";
import { COHATCH_MARKETS, APP_LOCATIONS } from "../../constant";

function CommunityPartnerForm() {
	const { userState } = useUserContext();
	const [formData, setFormData] = useState({
		orgName: "",
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		orgMission: "",
		orgAmount: "",
		spaceAmount: "",
		ourAsks: "",
		partnershipValue: "",
		termLength: "",
		coLocation: "",
		market: "",
		cpCategory: "",
	});
	const [coLocation, setCoLocation] = useState("");
	const [market, setMarket] = useState("");
	const [submitting, setSubmitting] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [errors, setErrors] = useState({}); // Error tracking

	const handleMarketChange = (event) => {
		if (event) {
			const { value } = event;
			setMarket(value);
			setCoLocation(null);
		} else {
			setMarket(null);
			setCoLocation(null);
		}
	};

	const generateCohatchMarket = () => {
		return Object.values(COHATCH_MARKETS).flat();
	};

	const generateCohatchLocation = () => {
		return market ? APP_LOCATIONS[market]?.locations || [] : [];
	};

	const handleLocationsChange = (event) => {
		if (event) {
			const { label } = event;
			setCoLocation(label);
		} else {
			setCoLocation(null);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
		if (errors[name]) {
			setErrors((prev) => ({ ...prev, [name]: false })); // Clear error if user types
		}
	};

	const validateFields = () => {
		const newErrors = {};
		for (const key in formData) {
			if (!formData[key] && key !== 'coLocation') {
				newErrors[key] = true; // Mark field as error
			}
		}
		if (!coLocation) newErrors.coLocation = true;

		setErrors(newErrors); // Set errors state
		return Object.keys(newErrors).length > 0; // Return if there are errors
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		setSubmitting(true);

		const hasErrors = validateFields();
		if (hasErrors) {
			setSubmitting(false);
			return;
		}

		try {
			const response = await submitCommunityPartner(formData, userState.accessToken);
			if (response.status === "success") {
				notify("Submission successful!", "success");
				setSubmitted(true);
			} else {
				const displayMessage = Array.isArray(response.message) ? response.message[0].message : response.message;
				notify(displayMessage || "An error has occurred. Please try again later.", "error");
			}
		} catch (error) {
			notify("Unable to send Invite. Please try again later.");
		} finally {
			setSubmitting(false);
		}
	};

	if (submitted) {
		return (
			<div className="py-8 px-4 text-center">
				<h2 className="text-2xl font-bold">Thank You!</h2>
				<p className="mt-2">Your submission has been received. We appreciate your interest!</p>
			</div>
		);
	}

	return (
		<div className="py-8 px-4 bg-white shadow-md rounded-lg">
			<form onSubmit={onSubmit} noValidate autoComplete="off" className="container mx-auto space-y-6">
				<h1 className="text-3xl font-bold text-center text-primary-400 mb-2">
					Community Partner Application
				</h1>
				<h2 className="text-xl font-semibold text-center text-success-800">
					Fill in the Details
				</h2>

				<div>
					<label className="font-semibold" htmlFor="orgName">Organization Name</label>
					<input
						type="text"
						name="orgName"
						id="orgName"
						className={`mt-2 block w-full p-3 border ${errors.orgName ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400`}
						placeholder="Enter Organization Name"
						onChange={handleChange}
						required
					/>
					{errors.orgName && <p className="text-red-500 text-sm">This field is required.</p>}
				</div>

				<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
					<div>
						<label className="font-semibold" htmlFor="firstName">Community Partner First Name:</label>
						<input
							type="text"
							name="firstName"
							id="firstName"
							className={`mt-2 block w-full p-3 border ${errors.firstName ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400`}
							placeholder="First Name"
							onChange={handleChange}
							required
						/>
						{errors.firstName && <p className="text-red-500 text-sm">This field is required.</p>}
					</div>

					<div>
						<label className="font-semibold" htmlFor="lastName">Community Partner Last Name:</label>
						<input
							type="text"
							name="lastName"
							id="lastName"
							className={`mt-2 block w-full p-3 border ${errors.lastName ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400`}
							placeholder="Last Name"
							onChange={handleChange}
							required
						/>
						{errors.lastName && <p className="text-red-500 text-sm">This field is required.</p>}
					</div>

					<div>
						<label className="font-semibold" htmlFor="email">Community Partner Email:</label>
						<input
							type="email"
							name="email"
							id="email"
							className={`mt-2 block w-full p-3 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400`}
							placeholder="Enter Email"
							onChange={handleChange}
							required
						/>
						{errors.email && <p className="text-red-500 text-sm">This field is required.</p>}
					</div>

					<div>
						<label className="font-semibold" htmlFor="phoneNumber">Community Partner Phone Number:</label>
						<input
							type="tel"
							name="phoneNumber"
							id="phoneNumber"
							className={`mt-2 block w-full p-3 border ${errors.phoneNumber ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400`}
							placeholder="Enter Phone Number"
							onChange={handleChange}
							required
						/>
						{errors.phoneNumber && <p className="text-red-500 text-sm">This field is required.</p>}
					</div>

					<div className="col-span-1 md:col-span-2">
						<label className="font-bold" htmlFor="coLocation">COhatch Location:</label>
						<div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-2 mb-6">
							<SelectInput
								label="COhatch City:"
								name="market"
								id="market"
								options={generateCohatchMarket()}
								noOptionsMessage="No cities found"
								placeholder="Choose City"
								setSelectedValue={handleMarketChange}
							/>
							<SelectInput
								id="coLocation"
								name="coLocation"
								options={generateCohatchLocation()}
								setSelectedValue={handleLocationsChange}
								placeholder="Choose Location"
							/>
						</div>
						{errors.coLocation && <p className="text-red-500 text-sm">COhatch location is required.</p>}
					</div>

					<div className="col-span-1 md:col-span-2">
						<label className="font-semibold" htmlFor="cpCategory">What category does this organization fall under?</label>
						<SelectInput
							name="cpCategory"
							id="cpCategory"
							placeholder="Choose Category"
							options={[
								{ value: "local_city_leaders", label: "Local City Leaders" },
								{ value: "chamber_of_commerce", label: "Chamber of Commerce" },
								{ value: "university_partner", label: "University Partner" },
								{ value: "local_influencer", label: "Local Influencer" },
								{ value: "networking_groups", label: "Networking Groups" },
								{ value: "events_and_advertising", label: "Events & Advertising" },
								{ value: "main_street_business", label: "Main Street Business" },
							]}
							setSelectedValue={(e) => {
								setFormData((prev) => ({ ...prev, cpCategory: e.label }));
								if (errors.cpCategory) setErrors((prev) => ({ ...prev, cpCategory: false })); // Clear error if user selects
							}}
							required
						/>
						{errors.cpCategory && <p className="text-red-500 text-sm">This field is required.</p>}
					</div>
				</div>

				<div className="space-y-4">
					<div>
						<label className="font-semibold" htmlFor="orgMission">Description of the organization and mission:</label>
						<textarea
							name="orgMission"
							id="orgMission"
							className={`mt-2 block w-full p-3 border ${errors.orgMission ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400`}
							rows="4"
							placeholder="Describe the organization and its mission."
							onChange={handleChange}
							required
						/>
						{errors.orgMission && <p className="text-red-500 text-sm">This field is required.</p>}
					</div>

					<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
						<div>
							<label className="font-semibold" htmlFor="orgAmount">How many coworkers will utilize COhatch?</label>
							<input
								type="number"
								name="orgAmount"
								id="orgAmount"
								className={`mt-2 block w-full p-3 border ${errors.orgAmount ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400`}
								placeholder="Enter Amount"
								onChange={handleChange}
								required
							/>
							{errors.orgAmount && <p className="text-red-500 text-sm">This field is required.</p>}
						</div>

						<div>
							<label className="font-semibold" htmlFor="spaceAmount">Total dollars awarded in coworking and/or meeting & event space:</label>
							<input
								type="text"
								name="spaceAmount"
								id="spaceAmount"
								className={`mt-2 block w-full p-3 border ${errors.spaceAmount ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400`}
								placeholder="Enter Space Amount"
								onChange={handleChange}
								required
							/>
							{errors.spaceAmount && <p className="text-red-500 text-sm">This field is required.</p>}
						</div>
					</div>

					<div>
						<label className="font-semibold" htmlFor="ourAsks">Our asks of the Community Partner:</label>
						<textarea
							name="ourAsks"
							id="ourAsks"
							className={`mt-2 block w-full p-3 border ${errors.ourAsks ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400`}
							rows="4"
							placeholder="What are your requests?"
							onChange={handleChange}
							required
						/>
						{errors.ourAsks && <p className="text-red-500 text-sm">This field is required.</p>}
					</div>

					<div>
						<label className="font-semibold" htmlFor="partnershipValue">Description of Partnership Value (ex: # of coworking memberships, # of meeting room hours, etc.):</label>
						<textarea
							name="partnershipValue"
							id="partnershipValue"
							className={`mt-2 block w-full p-3 border ${errors.partnershipValue ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400`}
							rows="4"
							placeholder="For example: 1 coworking membership; free 3 hour event quarterly"
							onChange={handleChange}
							required
						/>
						{errors.partnershipValue && <p className="text-red-500 text-sm">This field is required.</p>}
					</div>

					<div>
						<label className="font-semibold" htmlFor="termLength">Community Partnership Term (12 months is standard):</label>
						<input
							type="text"
							name="termLength"
							id="termLength"
							className={`mt-2 block w-full p-3 border ${errors.termLength ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400`}
							placeholder="Enter Term Length in Months"
							onChange={handleChange}
							required
						/>
						{errors.termLength && <p className="text-red-500 text-sm">This field is required.</p>}
					</div>
				</div>

				<div className="mt-6">
					<button
						className="w-full flex justify-center items-center gap-2 px-4 py-2 border border-transparent text-md font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-300"
						type="submit"
						disabled={submitting}
					>
						{submitting ? "Submitting..." : "Submit"}
					</button>
				</div>
			</form>
		</div>
	);
}

export default CommunityPartnerForm;
